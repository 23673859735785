// extracted by mini-css-extract-plugin
export var mdx = "styles-module--mdx--oZLYw";
export var h1 = "styles-module--h1--2i6bi";
export var h2 = "styles-module--h2--229o7";
export var h3 = "styles-module--h3--2-xXb";
export var h4 = "styles-module--h4--ZCXtT";
export var css0 = "styles-module--css-0--i0BKE";
export var gatsbyRespImageWrapper = "styles-module--gatsby-resp-image-wrapper--2h-xL";
export var cardArticle = "styles-module--card-article--284Sg";
export var p = "styles-module--p--19MUV";
export var header = "styles-module--header--1nv0f";
export var headerItem = "styles-module--headerItem--1FfMN";
export var marker = "styles-module--marker--_2JJt";