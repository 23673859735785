// extracted by mini-css-extract-plugin
export var mdx = "styles-module--mdx--1JuTA";
export var h1 = "styles-module--h1--4ezZS";
export var h2 = "styles-module--h2--1Ef_v";
export var h3 = "styles-module--h3--2TaIQ";
export var h4 = "styles-module--h4--2tZhj";
export var css0 = "styles-module--css-0--8NWRH";
export var gatsbyRespImageWrapper = "styles-module--gatsby-resp-image-wrapper--HtlAx";
export var cardArticle = "styles-module--card-article--1zL04";
export var p = "styles-module--p--33Sds";
export var iconMenu = "styles-module--iconMenu--190kq";
export var iconLink = "styles-module--iconLink--aw3PR";