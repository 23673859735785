// extracted by mini-css-extract-plugin
export var mdx = "styles-module--mdx--2NXBn";
export var h1 = "styles-module--h1--2KeQ4";
export var h2 = "styles-module--h2--3m80-";
export var h3 = "styles-module--h3--2ujDX";
export var h4 = "styles-module--h4--1XZ-V";
export var css0 = "styles-module--css-0--29d7Z";
export var gatsbyRespImageWrapper = "styles-module--gatsby-resp-image-wrapper--3f1aF";
export var cardArticle = "styles-module--card-article--1iHck";
export var p = "styles-module--p--3r_fK";
export var post = "styles-module--post--2dWrX";
export var titleRow = "styles-module--titleRow--yCEDO";
export var titleBorder = "styles-module--titleBorder--1gtLQ";
export var summary = "styles-module--summary--1nH68";
export var tags = "styles-module--tags--_7Hjg";