// extracted by mini-css-extract-plugin
export var mdx = "styles-module--mdx--2nNRc";
export var h1 = "styles-module--h1--196ZI";
export var h2 = "styles-module--h2--1F23c";
export var h3 = "styles-module--h3--17abC";
export var h4 = "styles-module--h4--OZkOr";
export var css0 = "styles-module--css-0--1p8vA";
export var gatsbyRespImageWrapper = "styles-module--gatsby-resp-image-wrapper--1Y_ZQ";
export var cardArticle = "styles-module--card-article--1E0UK";
export var p = "styles-module--p--2Ubik";
export var subheader = "styles-module--subheader--nRJ_9";