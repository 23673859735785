// extracted by mini-css-extract-plugin
export var mdx = "styles-module--mdx--3otKa";
export var h1 = "styles-module--h1--3n5CH";
export var h2 = "styles-module--h2--OIGfC";
export var h3 = "styles-module--h3--1ZStB";
export var h4 = "styles-module--h4--2STgX";
export var css0 = "styles-module--css-0--1ce-i";
export var gatsbyRespImageWrapper = "styles-module--gatsby-resp-image-wrapper--1hP1P";
export var cardArticle = "styles-module--card-article--3k3nI";
export var p = "styles-module--p--2YnLn";
export var sectionTitle = "styles-module--sectionTitle--1fqFx";