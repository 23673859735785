// extracted by mini-css-extract-plugin
export var mdx = "styles-module--mdx--3kVjN";
export var h1 = "styles-module--h1--1W-TX";
export var h2 = "styles-module--h2--2Cy_H";
export var h3 = "styles-module--h3---JU3b";
export var h4 = "styles-module--h4--zuGbA";
export var css0 = "styles-module--css-0--3MjW3";
export var gatsbyRespImageWrapper = "styles-module--gatsby-resp-image-wrapper--1A4Y-";
export var cardArticle = "styles-module--card-article--WGQYX";
export var p = "styles-module--p--3rbX9";
export var projectItem = "styles-module--projectItem--hOuFb";
export var titleAndSummary = "styles-module--titleAndSummary--1yA-0";
export var summary = "styles-module--summary--3ZyYr";
export var iconMenu = "styles-module--iconMenu--q1Lmq";