// extracted by mini-css-extract-plugin
export var mdx = "styles-module--mdx--1sctb";
export var h1 = "styles-module--h1--226xP";
export var h2 = "styles-module--h2--3CHEo";
export var h3 = "styles-module--h3--ZbU3Q";
export var h4 = "styles-module--h4--2kG18";
export var css0 = "styles-module--css-0--8SfPr";
export var gatsbyRespImageWrapper = "styles-module--gatsby-resp-image-wrapper--1q5Zd";
export var cardArticle = "styles-module--card-article--3yZ6d";
export var p = "styles-module--p--P2Q0k";
export var crumbs = "styles-module--crumbs--3g2uU";