// extracted by mini-css-extract-plugin
export var mdx = "styles-module--mdx--3Dq8s";
export var h1 = "styles-module--h1--1Fhtw";
export var h2 = "styles-module--h2--KDuOJ";
export var h3 = "styles-module--h3--3Qfp1";
export var h4 = "styles-module--h4--bIzi8";
export var css0 = "styles-module--css-0--3S25a";
export var gatsbyRespImageWrapper = "styles-module--gatsby-resp-image-wrapper--ckpTX";
export var cardArticle = "styles-module--card-article--ytgr-";
export var p = "styles-module--p--3lA5J";
export var tags = "styles-module--tags--2K9c-";