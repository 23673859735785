// extracted by mini-css-extract-plugin
export var mdx = "styles-module--mdx--vsuuz";
export var h1 = "styles-module--h1--ze3Mg";
export var h2 = "styles-module--h2--1vaDe";
export var h3 = "styles-module--h3--prX5T";
export var h4 = "styles-module--h4--1_0Mz";
export var css0 = "styles-module--css-0--10sF_";
export var gatsbyRespImageWrapper = "styles-module--gatsby-resp-image-wrapper--2PbuT";
export var cardArticle = "styles-module--card-article--vFOtn";
export var p = "styles-module--p--3ulE5";
export var link = "styles-module--link--ag4be";