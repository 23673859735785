import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Post } from "../../../../src/components/posts/Post";
import { PageTitle } from "../../../../src/components/PageTitle";
import { ProjectItem } from "../../../../src/components/ProjectItem";
import { useAssets } from "@flapper/gatsby-source-flapper/src/ui";
export default {
  Box,
  Flex,
  React,
  Post,
  PageTitle,
  ProjectItem,
  useAssets
};